
import Home from './components/pages/Home'
import Projects from './components/pages/Projects'
import Project from './components/pages/Project'
import About from './components/pages/About'
import AboutChild from './components/pages/AboutChild'
import Team from './components/pages/Team'
import Contact from './components/pages/Contact'
import ErrorPage from './components/pages/Error'


let routes = [
    
    {   
        path: '/', // ensure home is first item
        name: 'home',
        component: Home,
        meta: { title: 'Home' }
    },

    // use flat structure to prevent component nesting 
    {
        path: '/projects',
        name: 'projects',
        component: Projects,
        meta: { title: 'Projects' },
    },
    {
        path: '/projects/:slug', 
        name: 'project',
        component: Project,
        meta: { 
            title: 'Project',
            hidden: true
        }
    },
    {
        path: '/about',
        name: 'about',
        component: About,
        meta: { title: 'About' },
    },
    {
        path: '/about/:slug', 
        name: 'aboutchild',
        component: AboutChild,
        meta: { 
            title: 'About',
            hidden: true
        }
    },
    {
        path: '/team',
        name: 'team',
        component: Team,
        meta: { title: 'Team' },
    },
    {
        path: '/contact',
        name: 'contact',
        component: Contact,
        meta: { title: 'Contact' },
    },
    { 
        path: "*", // ensure Error is last item
        name: 'error',
        component: ErrorPage,
        props: true,
        meta: { 
            title: 'Error!',
            hidden: true 
        },
    }
]

export default {
    mode: 'history',
    routes: routes,
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
}