<template>
  <div class="masked-image">
    <div class="img-mask" :class="direction" ref="mask"></div>
    <slot></slot>
  </div>
</template>

<script>
  import gsap from "gsap";
  import ScrollTrigger from "gsap/ScrollTrigger";
  gsap.registerPlugin(ScrollTrigger)

  export default {
    props: {
      direction: {
        type: String,
        required: true
      },
    },
    data() {
      return {
        
      }
    },
    computed: {
      isVertical() {
        return this.direction === 'top' || this.direction === 'bottom'
      },
      isHorizontal() {
        return this.direction === 'left' || this.direction === 'right'
      },
      getRatio() {

      }
    },
    methods: {
    },
    mounted() {
      ScrollTrigger.defaults({
        // markers: true,
        scrub: 1,
        once: true,
        start: 'center bottom',
        end: 'top 50%',
      })
      
      let self = this
      let mask = this.$refs.mask

      gsap.to(mask, {
        scrollTrigger: mask,
        height: function() {
          return self.isVertical ? '0%' : false
        },
        width: function() {
          return self.isHorizontal ? '0%' : false
        }  
      })
    }
  }
</script>