
<template>
  <div class="grid gap-50" :class="classes">
    <slot></slot>
  </div>
</template>

<script>

  export default {
    props: {
      count: {
        type: Number, // blue | orange | black
        required: true
      },
    },
    data() {
      return {
      }
    },
    computed: {
      classes() {
        return `md-col-${this.count}`
      }
    }
  }
</script>