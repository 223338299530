import Images from './assets/img/**/*.*'
import Videos from './assets/video/**/*.*'
import Store from './store.js'

var Helpers = {}

Helpers.getImageSrc = (target) => {
  return Helpers.getSrc(target,Images);
}

Helpers.getVideoSrc = (target) => {
  return Helpers.getSrc(target,Videos);
}

Helpers.getSrc = (target, src) => {
  if(target !== undefined) {
    if(target.charAt(0) === '/') {
      target = target.substr(1); // remove leading slash if it was left in by accident
    }
    target = target.split('.')[0] // remove ext
    target = target.split('/') // split on slashes
  }
  target.forEach(function (value, index) {
    src = src[value]
  });

  if(Object.keys(src).length === 1) {
    return src[Object.keys(src)[0]]; // returns string
  } else {
    return src // returns object
  }
}

Helpers.fullUrl = (target) => {
  if(target !== undefined) {
    return 'https://***' + target
  }
}

Helpers.formatUrl = (url) => {
  if(url !== undefined) {
    if(url.startsWith('https://')) {
      url = url.split('https://')[1]
    }
    if(url.startsWith('http://')) {
      url = url.split('http://')[1]
    }
    if(url.startsWith('www.')) {
      url = url.split('www.')[1]
    }
    if(url.includes('/')) {
      url = url.split('/')[0];
    }
    return url
  }
}

Helpers.toKebab = (value) => {
  if(typeof value !== "string") {
    return value
  } else {
  	value = value.toLowerCase()
  	value = value.replace(/[^a-zA-Z0-9 ]/g, '-') // remove specials
  	value = value.replace(/ /g, '-') // remove spaces
  	value = value.replace(/-{1,}/g, '-') // remove multiple dashes
  	return value
  }
}


Helpers.formatTel = (number) => {
  if(number === undefined) {
    return false
  }

  if(number.startsWith('0800')) {
    return number
  }
  // number = number.replace(/\s/g, '')
  if(number.startsWith('0')) {
    number = number.substring(1)
  }
  number = '+64 ' + number
  return number
}

// useage : <p class="foo" v-html="$wrapText('p','foo', property.description)"></p>
Helpers.wrapText = (tag,className,data) => {
  let startTag = '<' + tag + '>'
  let endTag = '</' + tag + '>'
  if (className) {
    startTag = '<' + tag + ' class="' + className + '">';
  }
  if(data) {
    return data.replace(/\r\n\r\n?/g, endTag + startTag);
  }
}

Helpers.is = (breakpoint) => {
  return Store.state.breakpoint === breakpoint
}

Helpers.wait = () => {
  return Store.state.animationComplete ? ['wait','active'] : ['wait']
}


const install = function(Vue, options){
	Vue.prototype.$getImageSrc 				= Helpers.getImageSrc
	Vue.prototype.$getVideoSrc 				= Helpers.getVideoSrc
  Vue.prototype.$fullUrl            = Helpers.fullUrl
	Vue.prototype.$formatUrl 				  = Helpers.formatUrl
	Vue.prototype.$toKebab 						= Helpers.toKebab
	Vue.prototype.$formatTel 					= Helpers.formatTel
	Vue.prototype.$wrapText						= Helpers.wrapText
  Vue.prototype.$is                 = Helpers.is
  Vue.prototype.$wait               = Helpers.wait
}


Helpers.install = install
export default Helpers;

