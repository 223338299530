<template>
  <header v-scroll-lock="overlay">
    <div class="container">
      <router-link :to="{name: 'home'}" :class="{'disabled' : isHome}" class="logo">
        <img src="/assets/img/logo-compressed.svg">
      </router-link>
      <nav :class="navClasses">
        <app-nav :show-children="xsMode" :show-home="xsMode" :class="{'container' : xsMode}"></app-nav>
      </nav>
      <div v-if="xsMode" @click="openOverlay" class="hamburger" :class="{'active' : overlay}"></div>
    </div>
  </header>
</template>


<script>
  import AppNav from '~/components/AppNav'

  export default {
    components: { AppNav },
    data(){
      return {
        overlay: false,
        transition: false
      }
    },
    computed: {
      breakpoint() {
        return this.$store.state.breakpoint
      },
      isHome() {
        return this.$route.name === 'home'
      },
      showNav() {
        return !this.xsMode || this.overlay
      },
      xsMode() {
        return this.breakpoint === 'xs' || this.breakpoint === 'sm'
      },
      navClasses() {
        let result = []
        if(this.showNav) { 
          result.push('active') 
        }
        if(this.transition) { 
          result.push('transition') 
        }
        if(this.xsMode) { 
          result.push('xs-mode') 
        }
        return result
      },
    },
    methods: {
      openOverlay() {
        this.overlay = !this.overlay
        this.transition = true
        setTimeout(function() {
          this.transition = false
        }, 350);
      },
    },
    watch: {
      $route() {
        this.transition = false
        this.overlay = false
      },
      breakpoint() {
        this.transition = false
        this.overlay = false
      }
    },
  }
</script>