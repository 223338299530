<template>

  <scaffolding-helper v-if="loaded">
    <template v-slot:heading>
      <column-helper :count="2" class="xs-gap-25 md-gap-50">
        
        <!-- <router-link :to="{name: 'about'}" class="back col sm-span-2-col">Back to About</router-link> -->
        
        <heading-module>
          <template v-slot:heading1>{{entry.heading1}}</template>
          <template v-slot:heading2>{{entry.heading2}}</template>
          <template v-slot:heading3>{{entry.heading3}}</template>
          <template v-slot:byline>{{entry.byline}}</template>
        </heading-module>

        <div class="col intro" :class="$wait()">
          <p v-if="entry.bodyTitle" class="title">{{entry.bodyTitle}}</p>
          <div class="wysiwyg" v-html="entry.bodyContentWysiwyg"></div>
        </div>
      </column-helper>
    </template>

    <template v-slot:content>
      <mixed-media-grid :data="entry.media" :description="entry.mediaDescription"></mixed-media-grid>
      <image-list :data="entry"></image-list>
    </template>
  </scaffolding-helper>

</template>


<script>
  import ScaffoldingHelper from "~/components/ScaffoldingHelper.vue";
  import ColumnHelper from "~/components/ColumnHelper.vue";
  import HeadingModule from "~/components/HeadingModule.vue";
  import MixedMediaGrid from "~/components/MixedMediaGrid.vue";
  import ImageList from "~/components/ImageList.vue";
  import gql from 'graphql-tag'

  export default {
    components : { ScaffoldingHelper, ColumnHelper, HeadingModule, MixedMediaGrid, ImageList },
    data() {
      return {
        entry: Object,
      }
    },
    computed: {
      computedTitle() {
        return this.entry.title
      }
    },
    apollo: {
      entry: {
        query: gql`query variables($slug: [String!]){
                   entry(section: "aboutChild", slug: $slug) {
                    id
                    slug
                    title
                    ... on aboutChild_subPage_Entry {
                                              seoTitle seoDescription

                      heading1
                      heading2
                      heading3
                      bodyTitle
                      byline
                      bodyContentWysiwyg
                      media {
                        ... on media_mediagrid_BlockType {
                          image: mediaImage {
                            url
                            title
                            width
                            height
                            focalPoint
                            mimeType
                            s400: url @transform(width: 400)
                            s800: url @transform(width: 800)
                            s1200: url @transform(width: 1200)
                            s1600: url @transform(width: 1600)
                            s2000: url @transform(width: 2000)
                            s2400: url @transform(width: 2400)
                            s2800: url @transform(width: 2800)
                            s3200: url @transform(width: 3200)
                            ... on general_Asset {
                              altText
                            }
                          }
                          video: mediaVideo
                        }
                      }
                      mediaDescription
                      heading: detailHeading
                      description: detailDescription
                      items: imageList {
                        ... on imageList_imageList_BlockType {
                        title: itemTitle
                        content: itemContent
                        image: itemImage {
                          url
                          title
                          width
                          height
                          focalPoint
                          mimeType
                          s400: url @transform(width: 400)
                          s800: url @transform(width: 800)
                          s1200: url @transform(width: 1200)
                          s1600: url @transform(width: 1600)
                          s2000: url @transform(width: 2000)
                          s2400: url @transform(width: 2400)
                          s2800: url @transform(width: 2800)
                          s3200: url @transform(width: 3200)
                          ... on general_Asset {
                            altText
                          }
                        }
                        url: itemURL
                      }
                    } 
                    }
                  }
                
                  }`
                  ,
                  fetchPolicy: 'no-cache',
        variables() {      
          return {
            slug: this.$route.params.slug
          }
        },
        result ({ data, loading, networkStatus }) {
          if (data !== undefined){
            if (data.entry !== null){
              this.entry = data.entry
              console.log('GQL Data:')
              console.log(data.entry)
              if (data.entry.seoTitle !== null)
                this.$store.commit('setTitle',data.entry.seoTitle)
              else
                this.$store.commit('setTitle',data.entry.pageTitle)
              if (data.entry.seoDescription !== null)
                this.$store.commit('setDescription',data.entry.seoDescription)
              else
                this.$store.commit('setDescription','')
              this.$store.commit('setLoaded', true)
              this.$store.commit('setCraftLoaded', true)
            }
            else{
              this.$router.push({ name: 'error', params: {status: 500}})
            }
          }
          else{
            this.$router.push({ name: 'error', params: {status: 500}})
          }
        },
      }
    },
    methods: {
    },
    metaInfo() {
      return {
        title: 'Tuatara Structures : ' + this.computedTitle
      }
    },
    mounted() {
    }
  }
</script>