export default {
  state: {
    loaded: false,
    footerLoaded: false,
    title: '',
    breakpoint: '',
    footerData: null,
    fullURL: process.env.BASE_URL,
    animationComplete: false,
    description: '',
    settingsLoaded: false,
    craftLoaded: false,
    defaultDescription: '',
  },
  mutations: {
    setLoaded (state, payload){
      state.loaded = payload
    },
    setFooterLoaded (state, payload){
      state.footerLoaded = payload
    },
    setFooterData (state, payload){
      state.footerData = payload
    },
    setBreakpoint (state, payload){
      state.breakpoint = payload
    },
    setTitle (state, payload){
      state.title = payload
    },
    setDescription (state, payload){
      state.description = payload
    },
    setDefaultDescription (state, payload){
      state.defaultDescription = payload
    },
    setAnimationComplete (state, payload){
      state.animationComplete = payload
    },
    setSettingsLoaded (state, payload){
      state.settingsLoaded = payload
    },
    setCraftLoaded (state, payload){
      state.craftLoaded = payload
    },
  },
  actions: {
  }
}