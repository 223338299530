<template>
  <scaffolding-helper v-if="loaded">

    <template v-slot:heading>
      <column-helper :count="2">
        <heading-module>
          <template v-slot:heading1>{{entry.heading1}}</template>
          <template v-slot:heading2>{{entry.heading2}}</template>
          <template v-slot:heading3>{{entry.heading3}}</template>
          <template v-slot:byline>{{entry.byline}}</template>
        </heading-module>
      </column-helper>
    </template>
    
    <template v-slot:content>
      <column-helper :count="2">
        <div class="col">
          <line-helper class="md black"></line-helper>
          <ul class="primary">
            <li>
              <a :href="entry.mapLink" target="_blank">
                {{entry.address1}}<br>
                <template v-if="entry.address2">{{entry.address2}}<br/></template>
                {{entry.city}} {{entry.postcode}}<br/>
                {{entry.country}}
              </a>
            </li>
            <li v-if="entry.phone"><a :href="formatTel(entry.phone)">{{entry.phone}}</li>
            <li v-if="entry.email"><a :href="formatEmail(entry.email)">{{entry.email}}</li>
          </ul>

          <a v-if="isXsMode" :href="entry.mapLink" target="_blank" class="map">
            <inline-svg :src="$getImageSrc('map')"></inline-svg>
          </a>

          <template v-for="region in entry.regions">
            <ul class="region">
              <li class="strong">{{region.name}}</li>
              <li v-if="region.phone"><a :href="formatTel(region.phone)">{{region.phone}}</li>
              <li v-if="region.email"><a :href="formatEmail(region.email)">{{region.email}}</li>
            </ul>
          </template>
        </div>

        <a v-if="!isXsMode" :href="entry.mapLink" target="_blank" class="map">
          <inline-svg :src="$getImageSrc('map')"></inline-svg>
        </a>
      </column-helper>
    </template>

  </scaffolding-helper>
</template>

<script>
  import ScaffoldingHelper from "~/components/ScaffoldingHelper.vue";
  import ColumnHelper from "~/components/ColumnHelper.vue";
  import HeadingModule from "~/components/HeadingModule.vue";
  import LineHelper from "~/components/LineHelper.vue";
  import gql from 'graphql-tag'

  export default {
    components : { ScaffoldingHelper, ColumnHelper, HeadingModule, LineHelper},
    data() {
      return {
        entry: Object,
      }
    },
    apollo: {
      entry: {
        query: gql`{entry(section: "contact"){
                      id slug title
                      ...on contact_contact_Entry{
                        seoTitle seoDescription
                        heading1 heading2 heading3 byline 
                        address1 address2 city country postcode email phone mapLink
                        regions{
                          ...on regions_regions_BlockType{
                            name: regionName phone: regionPhone email: regionEmail
                          }
                        }
                      }
                    }
                  }`,
        result ({ data, loading, networkStatus }) {
          if (data.entry !== null){
            this.entry = data.entry
            if (data.entry.seoTitle !== null)
              this.$store.commit('setTitle',data.entry.seoTitle)
            else
              this.$store.commit('setTitle',data.entry.pageTitle)
            if (data.entry.seoDescription !== null)
              this.$store.commit('setDescription',data.entry.seoDescription)
            else
              this.$store.commit('setDescription','')
            this.$store.commit('setLoaded', true)
            this.$store.commit('setCraftLoaded', true)
            // console.log('GQL Data:')
            // console.log(data.entry)
          }
          else{
            this.$router.push({ name: 'error', params: {status: 500}})
          }
        },
      }
    },
    computed: {
      isXsMode() {
        let breakpoint = this.$store.state.breakpoint 
        return breakpoint === 'xs' || breakpoint === 'sm'
      }
    },
    methods: {
      formatTel(tel){
        return `tel: ${this.$formatTel(tel)}`
      },
      formatEmail(email){
        return `mailto: ${email}`
      }
    },
    mounted() {
    },
  }
</script>