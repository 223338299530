<template>
  <footer>
    <div class="container" v-if="this.$store.state.footerLoaded" >
      <div class="outer">

        <img src="/assets/img/logo-tagline.svg" class="logo" v-scroll-to="'main'">

        <div class="details">
          <ul>
            <li>Contact</li>
            <li v-if="footerData.footerEmail"><a :href="'mailto:' + footerData.footerEmail">{{footerData.footerEmail}}</a></li>
            <li v-if="footerData.footerPhone"><a :href="'tel:' + footerData.footerPhone">{{footerData.footerPhone}}</a></li>
            <li v-if="footerData.footerAddress1" class="has-gap">{{footerData.footerAddress1}}</li>
            <li v-if="footerData.footerAddress2">{{footerData.footerAddress2}}</li>
          </ul>
          <ul>
            <li>Social</li>
            <li v-if="footerData.linkedin"><a :href="footerData.linkedin" target="_blank">Linkedin</a></li>
            <li v-if="footerData.facebook"><a :href="footerData.facebook" target="_blank">Facebook</a></li>
            <li v-if="footerData.youtube"><a :href="footerData.youtube" target="_blank">Youtube</a></li>
          </ul>
          <p class="p-small col md-span-2-col">Copyright Tuatara Structures {{date}}. <span class="nowrap">All Rights reserved.</span></p>
        </div>
      </div>
      
      <img src="/assets/img/pattern.png">
    </div>
  </footer>
</template>


<script>

  export default {
    data(){
      return {}
    },
    computed: {
      date() {
        return new Date().getFullYear()
      },
      showBreak() {
        return this.$store.state.breakpoint === 'xs'
      },
      footerData(){
        return this.$store.state.footerData
      }
    }
  }
</script>