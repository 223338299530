
import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import Axios from 'axios';
import VueAxios from 'vue-axios';
import VueScrollTo from 'vue-scrollto'
import VueAnalytics from 'vue-analytics';
import Meta from 'vue-meta'
import Store from './store.js'
import Router from './router.js'
import Helpers from './helpers.js'
import {InlineSvgPlugin} from 'vue-inline-svg';
import VueObserveVisibility from 'vue-observe-visibility'
import VScrollLock from 'v-scroll-lock'
import ImageHelper from './components/ImageHelper.vue'
import CraftImageHelper from './components/CraftImageHelper.vue'
import App from './components/App.vue'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import schema from './schema.json'
import VueApollo from 'vue-apollo'

Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(VueAxios, Axios);
Vue.use(VueScrollTo)
Vue.use(Helpers)
Vue.use(Meta)
Vue.use(InlineSvgPlugin);
Vue.use(VueObserveVisibility)
Vue.use(VScrollLock)
Vue.use(VueApollo)

Vue.component('image-helper', ImageHelper)
Vue.component('craft-image-helper', CraftImageHelper)

let axiosBaseDomain = process.env.BASE_URL
let routerImport = Router

const host = window.location.host;
axiosBaseDomain = process.env.BASE_URL

Axios.defaults.baseURL = axiosBaseDomain;
let m = document.location.href.match(/\btoken=([^&]+)/);
let token = m ? m[1] : '';
// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: `${axiosBaseDomain}/api?token=${token}`
})

// Retrieve schema map
const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: schema
})

// Cache implementation
const cache = new InMemoryCache({fragmentMatcher})

// Create the apollo client
const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
})

const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
})

const router = new VueRouter(Router)
const store = new Vuex.Store(Store);

Vue.use(VueAnalytics, {
  id: 'UA-91588815-1',
  router
})

router.beforeEach((to, from, next) => {
  store.commit('setLoaded', false)
  store.commit('setCraftLoaded', false)
  next();
});

// get loaded state globally
Vue.mixin({
  computed: {
   craftLoaded: {
     get() {
       return this.$store.state.craftLoaded
     }
    },
    settingsLoaded: {
     get() {
       return this.$store.state.settingsLoaded
     }
    },
    loaded() {
     return this.craftLoaded && this.settingsLoaded
    },
  }
})

new Vue({
	router,
	store,
	apolloProvider,
	render: createElement => createElement(App)
}).$mount('#app');