
<template>
  <div class="line"></div>
</template>

<script>
  export default {
    data() {
      return {
      }
    },
    computed: {
    },
  }
</script>