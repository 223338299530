<template>
  <ul>
    <menu-item 
      v-for="(r,i) in routes" 
      :key="i" 
      :route="r" 
      :show-children="showChildren" 
      :show-home="showHome">
    </menu-item>
  </ul>
</template>


<script>
  import MenuItem from '~/components/MenuItem'

  export default {
    components: { MenuItem },
    props: {
      route: Object,
      showChildren: {
        type: Boolean,
        default: true
      },
      showHome: {
        type: Boolean,
        default: true
      }
    },
    data(){
      return {
      }
    },
    computed: {
      routes() {
        // converts a flat structure to a nested children structure
        // allows nested menu output without having to have nesting in $router
        // will bypass any existing nesting allowing this to work regardless of structure
        // - 
        // Note : requires home (/) and error (*) to be first and last items in $router.options.routes
        let obj = Object.assign(this.$router.options.routes)
        let length = Object.keys(obj).length
        let parentPath = ''
        let parentKey
        let children = []
        let result = []

        Object.keys(obj).forEach(function (key, index) {

          // if home or error just push to result and carry on
          if(obj[key].path === '/' || obj[key].path === '*') {
            result.push(obj[key])
          } else {

            // no parent path, better set one
            if(parentPath === '') {
              parentKey = key
              parentPath = obj[key].path
            }

            if(obj[key].path.includes(parentPath) && obj[key].path !== parentPath) {
              // found a child, push it to children array
              children.push(obj[key])
            } else {

              // push children to result
              if(children.length) {
                result[parentKey].children = children
              }

              // set new parent and reset children
              parentKey = key
              parentPath = obj[key].path
              children = []

              // push parent to result
              result[parentKey] = obj[parentKey]
            }
          }
        });
        //remove empty results
        result = result.filter(n => n)
        return result;
      },
    },
  }
</script>