<template>
  <div class="keypoint">
    <line-helper class="md black"></line-helper>
    <p class="title">{{data.title}}</p>
    <div class="wysiwyg" v-html="data.content"></div>
  </div>
</template>

<script>
  import LineHelper from "~/components/LineHelper.vue";

  export default {
    components : { LineHelper },
    props: {
      data: {
        type: Object,
        required: true 
      }
    },
    data() {
      return {
      }
    },
    computed: {
    },
    methods: {
    },
    mounted() {
    }
  }
</script>