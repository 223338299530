<template>
  <scaffolding-helper v-if="loaded">

    <template v-slot:heading>
      <column-helper :count="2">
        <heading-module>
          <template v-slot:heading1>{{entry.heading1}}</template>
          <template v-slot:heading2>{{entry.heading2}}</template>
          <template v-slot:heading3>{{entry.heading3}}</template>
          <template v-slot:byline>{{entry.byline}}</template>
        </heading-module>
      </column-helper>
    </template>

    <template v-slot:content>
      <column-helper :count="2">
        <div class="project-teaser col"
             ref="wrapper"
             v-for="(project,index) in projects" 
             @click="goToProject(index)" 
             @mouseover="setupMasks()">
          <div class="mask" ref="mask"></div>
          <div class="img-wrapper is-cover three-two">
            <!-- <img ref="img" :src="$getImageSrc('temp')"> -->
            <craft-image-helper :src="project.projectImages[0]" ref="img"></craft-image-helper>
          </div>
          <span>{{project.name}} ›</span>
          <line-helper class="sm black"></line-helper>
        </div>
      </column-helper>
    </template>

  </scaffolding-helper>
</template>

<script>
  import ScaffoldingHelper from "~/components/ScaffoldingHelper.vue";
  import ColumnHelper from "~/components/ColumnHelper.vue";
  import HeadingModule from "~/components/HeadingModule.vue";
  import LineHelper from "~/components/LineHelper.vue";
  import gql from 'graphql-tag'

  export default {
    components : { ScaffoldingHelper, ColumnHelper, HeadingModule, LineHelper},
    data() {
      return {
        mask: {
          activeIndex: null,
          height: false,
          width: false,
          refs: [],
          timer: 750,
        },
        entry: Object,
        projects: Array,
      }
    },
    computed: {
    },
    apollo: {
      // site will become param based on current route
      projects: {
        query: gql`query { 
          entry: entry(section: "projectsList"){
            id slug title
            ...on projectsList_projectsList_Entry{
              heading1 heading2 heading3 byline
              seoTitle seoDescription
            }
          }
          projects: entries(section: "projects"){
          id slug
          ...on projects_default_Entry{
            name: projectName
            projectImages (limit:1){
              url,
                title,
                width,
                height,
                focalPoint,
                mimeType,
                s400: url @transform(width: 400),
                s800: url @transform(width: 800),
                s1200: url @transform(width: 1200),
                s1600: url @transform(width: 1600),
                s2000: url @transform(width: 2000),
                s2400: url @transform(width: 2400),
                s2800: url @transform(width: 2800),
                s3200: url @transform(width: 3200)
                ...on general_Asset{
                  altText
                }
            }
          }
        }
        }`,
        result ({ data, loading, networkStatus }) {
          if (data !== undefined){
            if (data.projects !== null){
              //console.log('GQL Data:')
              this.entry = data.entry
              this.projects = data.projects
              
              if (data.entry.seoTitle !== null)
                this.$store.commit('setTitle',data.entry.seoTitle)
              else
                this.$store.commit('setTitle',data.entry.pageTitle)
              if (data.entry.seoDescription !== null)
                this.$store.commit('setDescription',data.entry.seoDescription)
              else
                this.$store.commit('setDescription','')
              this.$store.commit('setLoaded', true)              
              this.$store.commit('setCraftLoaded', true)
              // console.log(data.entry)
              // console.log(data.projects)
            }
            else{
              this.$router.push({ name: 'error',params: {status: 500}})
            }
          }
          else{
            this.$router.push({ name: 'error', params: {status: 500}})
          }
        },
      }
    },
    fetchPolicy: 'no-cache',
    methods: {
      setupMasks() {
        // masks need to be absolute positioned before expand to scroll smoothly, but need to be fixed to expand correctly on click
        // this method will setup up all the variables we need to achive this

        if(this.$refs !== undefined && this.$store.state.loaded) {

          // set width / height
          this.mask.width = this.$refs.img[0].$refs.img.getBoundingClientRect().width
          this.mask.height = this.$refs.img[0].$refs.img.getBoundingClientRect().height

          // reset refs
          this.mask.refs = []

          // loop and get refs for each mask
          let self = this
          this.projects.forEach(function (value, index) {
            let result = {
              width : self.$refs.img[index].$refs.img.getBoundingClientRect().width,
              height : self.$refs.img[index].$refs.img.getBoundingClientRect().height,
              absLeft : self.$refs.wrapper[index].offsetLeft,
              absTop : self.$refs.wrapper[index].offsetTop,
              fixedLeft : self.$refs.wrapper[index].getBoundingClientRect().left,
              fixedTop : self.$refs.wrapper[index].getBoundingClientRect().top,
            }
            // console.log(result)
            self.mask.refs.push(result)
            self.pushMaskStyles(index)
          });
        }
      },
      pushMaskStyles(index) {
        // this method handles the expand animation on click utilising the previously setup refs
        // We cant do this inline with vue :style or computed because we cant get the index to push the right refs
        // this method is verbose but its reliable.

        let target = this.$refs.mask[index].style

        if(this.mask.activeIndex === index) { // fixed (expanding)
          target.left = this.mask.refs[index].fixedLeft + 'px'
          target.top = this.mask.refs[index].fixedTop + 'px'
          setTimeout(()=>{
            target.position = 'fixed'
            target.transition = `all ${this.mask.timer}ms cubic-bezier(0.42,0,0.58,1)`
            target.left = 0 + 'px'
            target.top = 0 + 'px'
            target.width = window.innerWidth + 'px'
            target.height = window.innerHeight + 'px'
            target.opacity = '1'
          },10);
        } else { // absolute (inactive / scrolling)
          target.width = this.mask.width + 'px'
          target.height = this.mask.height + 'px'
          target.left = this.mask.refs[index].absLeft + 'px'
          target.top = this.mask.refs[index].absTop + 'px'
        }
      },
      goToProject(index) {
        // this one simply sets the correct active index on click and triggers the animation
        // the timeout aligns with the timer for the animation so the router fires on completion
        this.mask.activeIndex = index
        this.pushMaskStyles(index)

        window.removeEventListener('resize',this.setupMasks);
        window.removeEventListener('scroll',this.setupMasks)

        setTimeout(()=>{
          //this.$router.push({ name: this.data.projects[index].slug })
          this.$router.push({ name: 'project', params: {slug: this.projects[index].slug }})
        },this.mask.timer);
      },
    },
    created() {
      window.addEventListener('resize',this.setupMasks);
      window.addEventListener('scroll',this.setupMasks);
    },
    destroyed() {
      window.removeEventListener('resize',this.setupMasks);
      window.removeEventListener('scroll',this.setupMasks)
    }
  }
</script>