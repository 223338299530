
<template>
  <div class="image-list">
    <h4 v-if="data.heading">{{data.heading}}</h4>
    <p v-if="data.description" class="p-big">{{data.description}}</p>
    <div class="item-wrapper" :class="{'has-gap' : hasGap}">
      <div v-for="item in data.items" class="item">
        <div class="col">
          <div v-if="item.image.length" class="img-wrapper is-cover three-two">
            <img v-if="isSvg(item.image[0])" :src="$store.state.fullURL + item.image[0].url"> 
            <craft-image-helper v-else :src="item.image[0]"></craft-image-helper>
          </div>
        </div>
        <div class="col">
          <p class="title">{{item.title}}</p>
          <div class="wysiwyg" v-html="item.content"></div>
          <a v-if="item.url" :href="item.url" class="learn-more">Learn more ›</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    props: {
      data: { // enable this
        type: Object,
        required: true
      }
    },
    data(){
      return {
      }
    },
    computed: {
      hasGap() {
        return this.data.heading || this.data.description
      },
    },
    methods: {
      isSvg(image) {
        return image.mimeType.includes('svg')
      }
    }
  }
</script>