<template>
  <li v-if="visibleParent">
    <router-link v-if="show(route)" :to="{name: route.name}">{{name}}</router-link>
    <template v-if="children && children.length && visibleChildren && showChildren">
      <ul class="children">
        <menu-item v-for="(r,i) in route.children" :key="i" :route="r"></menu-item>
      </ul>
    </template>
  </li>
</template>


<script>
  export default {
    name: 'menu-item',
    props: {
      route: Object,
      showChildren: Boolean,
      showHome: Boolean
    },
    data(){
      return {
      }
    },
    computed: {
      children() {
        return this.route.children
      },
      visibleParent() {
        return !this.route.meta.hidden
      },
      visibleChildren() {
        let obj = this.children
        let hiddenItems = 0

        if(obj) {
          Object.keys(obj).forEach(function (key) {
            if(obj[key].meta.hidden) { 
              hiddenItems ++
            }
          });
        }

        if(obj.length > hiddenItems) {
          return true
        } else {
          return false
        }
      },
      name() {
        return this.route.meta.title
      }
    },
    methods: {
      show(route) {
        return (route.name === 'home' && this.showHome === false) ? false : true
      }
    }
  }
</script>