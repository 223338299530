
<template>
  <div class="heading-module" :class="{'static' : static}" ref="parent">
    <line-helper class="lg orange"></line-helper>
    <h1 v-if="hasHeading1"><slot name="heading1"></slot></h1>
    <h1 v-if="hasHeading2"><slot name="heading2"></slot></h1>
    <h1 v-if="hasHeading3"><slot name="heading3"></slot></h1>
    <h5 v-if="hasByline"><slot name="byline"></slot></h5>
  </div>
</template>

<script>
  import gsap from "gsap";
  import ScrollTrigger from "gsap/ScrollTrigger";
  gsap.registerPlugin(ScrollTrigger)

  import { EventBus } from '/event-bus.js';
  import LineHelper from "~/components/LineHelper.vue";

  export default {
    components : { LineHelper },
    props: {
      static: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
      }
    },
    computed: {
      hasHeading1() {
        return !!this.$slots.heading1 && this.$slots.heading1[0].text !== ''
      },
      hasHeading2() {
        return !!this.$slots.heading2 && this.$slots.heading2[0].text !== ''
      },
      hasHeading3() {
        return !!this.$slots.heading3 && this.$slots.heading3[0].text !== ''
      },
      hasByline() {
        return !!this.$slots.byline && this.$slots.byline[0].text !== ''
      },
    },
    methods: {
    },
    mounted() {
      if(!this.static) {
        let parent = this.$refs.parent
        let children = Array.from(this.$refs.parent.children)
        let line = children[0]
        let headings = children.slice(1)
        let scrollTrigger = (this.$route.name === 'home') ? parent : false;

        ScrollTrigger.defaults({
          // markers: true,
          once: true,
          start: 'top bottom',
        })

        gsap.timeline({onComplete: this.complete, scrollTrigger: scrollTrigger }) // scrollTrigger: line
            .to(line, {duration: 1, width: '100%', ease: "power3.out"})
            .to(headings, { y: 0, opacity: 1, ease: "power3.out", stagger: 0.33})
            .then(() => {
              this.$store.commit('setAnimationComplete',true)
            })
      }
    }
  }
</script>