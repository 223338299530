<template>
  <div class="video-wrapper is-cover" :class="ratio" :key="video.thumb">
<!--     <pre>
      {{video}}
    </pre> -->
    <template v-if="!showVideo">
      <div v-if="$slots.default" class="trigger">
        <slot></slot>
      </div>
      <img v-if="thumbLoaded" :src="video.thumb" @click="showVideo = true" class="thumb">
    </template>
    
    <iframe 
      ref="video"
      v-else
      :src="video.src" 
      frameborder="0" 
      allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" 
      allowfullscreen>
    </iframe>
  </div>
</template>


<script>
  export default {
    props: {
      src: String,
      ratio: {
        type: String,
        default: 'sixteen-nine'
      },
      lowResThumb: {
        type: Boolean,
        default: false
      },
      remotePlay: Boolean
    },
    data(){
      return {
        showVideo: false,
        video: {},
        thumbLoaded: false
      }
    },
    computed: {
    },
    methods: {
      parseVideo(url) {
        let self = this
        let video = {}
        let xmlHttp = new XMLHttpRequest();

        // get type / id
        url.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/);

        if (RegExp.$3.indexOf('youtu') > -1) {
          video.type = 'youtube';
        } else if (RegExp.$3.indexOf('vimeo') > -1) {
          video.type = 'vimeo';
        }
      
        video.id = RegExp.$6

        // get src
        if(video.type == 'youtube') {
          video.src = `https://www.youtube-nocookie.com/embed/${video.id}?autoplay=1`
        } else {
          video.src = `https://player.vimeo.com/video/${video.id}?autoplay=1`
        }

        // get thumb
        if (video.type == 'youtube') {
          if(this.lowResThumb) {
            video.thumb = (`//img.youtube.com/vi/${video.id}/0.jpg`);
          } else {
            video.thumb = (`//img.youtube.com/vi/${video.id}/maxresdefault.jpg`);
          }
          this.thumbLoaded = true
          
        } else if (video.type == 'vimeo') {
          xmlHttp.onreadystatechange = function() { 
            if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
              video.thumb = JSON.parse(xmlHttp.responseText)[0].thumbnail_large;
              self.thumbLoaded = true
            }
          }
          xmlHttp.open("GET", `//vimeo.com/api/v2/video/${video.id}.json`, true); // true for asynchronous 
          xmlHttp.send(null);
        }
        // console.log(video)
        this.video = video
        
      },
    },
    watch: {
      remotePlay() {
        this.showVideo = true
      }
    },
    mounted() {
      this.parseVideo(this.src)
    },
  }
</script>