
<template>
  <div v-if="this.$store.state.loaded" :id="id" class="default-gap" :class="{'container' : !hasHero}">
    <div v-if="hasHero" class="hero-wrapper fluid-container">
      <slot name="hero"></slot>
    </div>

    <div class="fluid-container default-gap" style="background: white; z-index: 1">
      <div v-if="hasHeading" class="heading-wrapper" :class="{'container' : hasHero}">
        <slot name="heading"></slot>
      </div>
      <div class="content-wrapper default-gap" :class="wait">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
      }
    },
    computed: {
      id() {
        return this.$route.name
      },
      wait() {
        let classes = ['wait']
        if(this.$store.state.animationComplete) {
          classes.push('active')
        }
        if(this.hasHero) {
          classes.push('container')
        }
        return classes
      },
      hasHeading() {
        return !!this.$slots.heading
      },
      hasHero() {
        return !!this.$slots.hero
      }
    },
    mounted() {
    }
  }
</script>