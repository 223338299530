<template>
  <scaffolding-helper v-if="loaded">

    <template v-slot:heading>
      <column-helper :count="2">
        <heading-module>
          <template v-slot:heading1>{{entry.heading1}}</template>
          <template v-slot:heading2>{{entry.heading2}}</template>
          <template v-slot:heading3>{{entry.heading3}}</template>
          <template v-slot:byline>{{entry.byline}}</template>
        </heading-module>
      </column-helper>
    </template>

    <template v-slot:content>
      <column-helper class="sm-col-2" :count="3">
          <div v-for="staff in entry.profiles" class="col staff-member">
            <line-helper class="md black"></line-helper>
            <ul>
              <li class="is-cover one-one">
                <craft-image-helper :src="staff.image[0]"></craft-image-helper>
              </li>
              <li>{{staff.firstName}} {{staff.lastName}}</li>
              <li>{{staff.position}}</li>
              <li>{{staff.bio}}</li>
              <li v-if="staff.phone"><a :href="formatTel(staff.phone)">{{staff.phone}}</li>
              <li v-if="staff.email"><a :href="formatEmail(staff.email)">{{staff.email}}</li>
            </ul>
          </div>
      </column-helper>
    </template>

  </scaffolding-helper>
</template>

<script>
  import ScaffoldingHelper from "~/components/ScaffoldingHelper.vue";
  import ColumnHelper from "~/components/ColumnHelper.vue";
  import HeadingModule from "~/components/HeadingModule.vue";
  import LineHelper from "~/components/LineHelper.vue";
  import gql from 'graphql-tag'

  export default {
    components : { ScaffoldingHelper, ColumnHelper, HeadingModule, LineHelper },
    data() {
      return {
        entry: Object,
      }
    },
    apollo: {
      entry: {
        query: gql`{entry(section: "team"){
                      id slug title
                      ...on team_team_Entry{
                        heading1 heading2 heading3 byline
                        seoTitle seoDescription
                        profiles{
                          ...on profiles_profiles_BlockType{
                            firstName lastName position: jobTitle bio phone email 
                            image: profileImage {
                              url,
                              title,
                              width,
                              height,
                              focalPoint,
                              mimeType,
                              s400: url @transform(width: 400),
                              s800: url @transform(width: 800),
                              s1200: url @transform(width: 1200),
                              s1600: url @transform(width: 1600),
                              s2000: url @transform(width: 2000),
                              s2400: url @transform(width: 2400),
                              s2800: url @transform(width: 2800),
                              s3200: url @transform(width: 3200)
                              ...on general_Asset{
                                altText
                              }
                            }
                          }
                        }
                      }
                    }
                  }`,
        result ({ data, loading, networkStatus }) {
          if (data !== undefined){
            if (data.entry !== null){
              this.entry = data.entry
              if (data.entry.seoTitle !== null)
                this.$store.commit('setTitle',data.entry.seoTitle)
              else
                this.$store.commit('setTitle',data.entry.pageTitle)
              if (data.entry.seoDescription !== null)
                this.$store.commit('setDescription',data.entry.seoDescription)
              else
                this.$store.commit('setDescription','')
              this.$store.commit('setLoaded', true)              
              this.$store.commit('setCraftLoaded', true)
              // console.log('GQL Data:')
              // console.log(data.entry)
            }
            else{
              this.$router.push({ name: 'error', params: {status: 500}})
            }
          }
          else{
            this.$router.push({ name: 'error', params: {status: 500}})
          }
        },
      }
    },
    computed: {
     
    },
    methods: {
      formatTel(tel){
        return `tel: ${this.$formatTel(tel)}`
      },
      formatEmail(email){
        return `mailto: ${email}`
      }
    },
    mounted() {
    },
  }
</script>