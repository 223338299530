// Usage example : 

// import { EventBus } from '/event-bus.js';

// EventBus.$emit('emitName', 'some emit content - object, array, string, boolean');

// EventBus.$on('emitName', (emit) => {
//   console.log('emit received : ' + emit)
// });

import Vue from 'vue';
export const EventBus = new Vue();