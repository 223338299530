<template>

  <scaffolding-helper v-if="loaded">
    <template v-slot:heading>
      <column-helper :count="2" class="xs-gap-25 md-gap-50">
        <heading-module>
          <template v-slot:heading1>{{entry.heading1}}</template>
          <template v-slot:heading2>{{entry.heading2}}</template>
          <template v-slot:heading3>{{entry.heading3}}</template>
          <template v-slot:byline>{{entry.byline}}</template>
        </heading-module>

        <div class="col intro" :class="$wait()">
          <p class="title">{{entry.bodyTitle}}</p>
          <div class="wysiwyg" v-html="entry.bodyContentWysiwyg"></div>
          <ul v-if="childPages.length" class="submenu">
            <li>Learn more.</li>
            <li v-for="item in childPages"><router-link :to="{name: 'aboutchild', params: { slug: item.slug }}">{{item.title}} ›</router-link></li>
          </ul>
        </div>
      </column-helper>
    </template>

    <template v-slot:content>
      <column-helper :count="2" class="xs-gap-25 md-gap-50">
        <template v-for="(image,index) in entry.aboutImages">
          <masked-image :direction="getDirection(index)" class="is-cover three-two">
            <craft-image-helper :src="image"></craft-image-helper>
          </masked-image>
        </template>
      </column-helper>

      <div class="keypoints default-gap">
        <column-helper :count="2">
          <h3 class="orange">{{entry.keypoints[0].keypointHeading}}</h3>
        </column-helper>
        
        <column-helper :count="3">
          <keypoint-module v-for="item in entry.keypoints[0].items" :data="item">
          </keypoint-module>
        </column-helper>
      </div>
    </template>
  </scaffolding-helper>

</template>


<script>
  import ScaffoldingHelper from "~/components/ScaffoldingHelper.vue";
  import ColumnHelper from "~/components/ColumnHelper.vue";
  import HeadingModule from "~/components/HeadingModule.vue";
  import KeypointModule from "~/components/KeypointModule.vue";
  import MaskedImage from "~/components/MaskedImage.vue";
  import gql from 'graphql-tag'

  export default {
    components : { ScaffoldingHelper, ColumnHelper, HeadingModule, KeypointModule, MaskedImage },
    data() {
      return {
        entry: Object,
        childPages: Array
      }
    },
    computed: {
    },
    apollo: {
      entry: {
        query: gql`{entry(section: "about"){
                      id slug title
                      ...on about_about_Entry{
                        heading1 heading2 heading3 bodyTitle byline bodyContentWysiwyg
                                                seoTitle seoDescription

                        aboutImages{
                            url,
                              title,
                              width,
                              height,
                              focalPoint,
                              mimeType,
                              s400: url @transform(width: 400),
                              s800: url @transform(width: 800),
                              s1200: url @transform(width: 1200),
                              s1600: url @transform(width: 1600),
                              s2000: url @transform(width: 2000),
                              s2400: url @transform(width: 2400),
                              s2800: url @transform(width: 2800),
                              s3200: url @transform(width: 3200)
                              ...on general_Asset{
                                altText
                              }
                          }
                        keypoints{
                          ...on keypoints_keypoints_BlockType{
                            keypointHeading keypointByline
                            items{
                              ...on items_BlockType{
                                title: itemTitle content: itemContent
                              }
                            }
                          }
                        }
                      }
                    }
                    childPages: entries (section: "aboutChild") 
                      { title, slug }
                  }`,
        result ({ data, loading, networkStatus }) {
          if (data !== undefined){
            if (data.entry !== null){
              this.entry = data.entry
              this.childPages = data.childPages
              if (data.entry.seoTitle !== null)
                this.$store.commit('setTitle',data.entry.seoTitle)
              else
                this.$store.commit('setTitle',data.entry.pageTitle)
              if (data.entry.seoDescription !== null)
                this.$store.commit('setDescription',data.entry.seoDescription)
              else
                this.$store.commit('setDescription','')
              this.$store.commit('setLoaded', true)
              this.$store.commit('setCraftLoaded', true)
              // console.log('GQL Data:')
              // console.log(data.entry)
            }
            else{
              this.$router.push({ name: 'error', params: {status: 500}})
            }
          }
          else{
            this.$router.push({ name: 'error', params: {status: 500}})
          }
        },
      }
    },
    methods: {
      getDirection(index) {
        let breakpoint = this.$store.state.breakpoint
        if(breakpoint === 'xs' || breakpoint === 'sm') {
          return index % 2 ? 'left' : 'right';
        } else {
          return index % 2 ? 'top' : 'bottom';
        }
      }
    },
    mounted() {
    }
  }
</script>