<template>
  <scaffolding-helper v-if="loaded">
    <template v-slot:hero>
      <div class="container">
        <column-helper :count="2">
          <heading-module class="heading-wrapper" :style="{'padding-bottom' : bylineHeight}">
            <template v-slot:heading1>{{entry.heroTitle}}</template>
          </heading-module>
          <div class="byline-wrapper">
            <h2 class="byline" :class="$wait()" ref="byline">{{entry.byline}}</h2>
          </div>
        </column-helper>
      </div>
    </template>
    
    <template v-slot:content>
      <column-helper :count="2">
        <div class="col" :class>
          <router-link :to="{name: 'projects'}" class="back">Back to Projects</router-link>
          <heading-module :static="true">
            <template v-slot:heading1>{{entry.bodyTitle}}</template>
          </heading-module>
          <div class="wysiwyg" :class="{'no-margin' : !multiImg, 'is-video' : entry.projectVideo}">
            <div class="intro">{{entry.bodyIntroduction}}</div>
            <p>{{entry.bodyContent}}</p>
          </div>
         <!--  <pre>
            multiImg : {{multiImg}}
            video : {{hasVideo}}
          </pre> -->
        </div>

        

        <!-- video -->
        <div v-if="hasVideo" class="col first-img">
          <video-helper :src="entry.projectVideo" :ratio="'three-two'">
            <img src="/assets/img/play.svg">
          </video-helper>
        </div>

        <!-- multi image grid (backfill first image) -->
        <div v-if="multiImg && !hasVideo" class="col first-img">
          <masked-image :direction="'bottom'" class="is-cover three-two">
            <craft-image-helper :src="entry.projectImages[0]"></craft-image-helper>
          </masked-image>
        </div>
      </column-helper>

      <column-helper :count="2" class="xs-gap-15 md-gap-50">
        <!-- single wide image -->
        <template v-if="!multiImg">
          <masked-image :direction="'bottom'" class="is-cover col three-two md-span-2-col">
            <craft-image-helper :src="entry.projectImages[0]"></craft-image-helper>
          </masked-image>
        </template>

        <!-- multi image grid (exclude first image) -->
        <template v-if="multiImg && !hasVideo">
          <template v-for="(image,index) in entry.projectImages" v-if="index !== 0" >
            <div class="col" :class="imgClasses(index)" :key="isXsMode + index">
              <masked-image :direction="getDir(index)" class="is-cover three-two">
                <craft-image-helper :src="image"></craft-image-helper>
              </masked-image>
            </div>
          </template>
        </template>

        <!-- multi image grid (include first image) -->
        <template v-if="multiImg && hasVideo">
          <template v-for="(image,index) in entry.projectImages">
            <div class="col" :class="imgClasses(index)" :key="isXsMode + index">
              <masked-image :direction="getDir(index)" class="is-cover three-two">
                <craft-image-helper :src="image"></craft-image-helper>
              </masked-image>
            </div>
          </template>
        </template>

      </column-helper>
    </template>

  </scaffolding-helper>
</template>

<script>
  import ScaffoldingHelper from "~/components/ScaffoldingHelper.vue";
  import ColumnHelper from "~/components/ColumnHelper.vue";
  import HeadingModule from "~/components/HeadingModule.vue";
  import MaskedImage from "~/components/MaskedImage.vue";
  import VideoHelper from "~/components/VideoHelper.vue";
  import gql from 'graphql-tag'

  export default {
    components : { ScaffoldingHelper, ColumnHelper, HeadingModule, MaskedImage, VideoHelper },
    data() {
      return {
        bylineHeight: false,
        bylineActive: false,
        entry: Object,
        // data: {
        //   hero: {
        //     title: 'Dynes Transport',
        //     byline: 'Designed and delivered in 20 weeks.'
        //   },
        //   body: {
        //     title: 'Dynes Transport',
        //     content: '<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p><p>Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat duis autem vel eum iriure.</p><p>Dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum delenit augue.</p>'
        //   },
        //   images: [
        //     {
        //       src: 'temp'
        //     },
        //  ]
        // }
      }
    },
    apollo: {
      project: {
        query: gql`query variables($slug: [String!]){
                      project: entry(section: "projects", slug: $slug){
                        id slug title
                        ...on projects_default_Entry{
                          seoTitle seoDescription
                          heroTitle: projectName
                          byline
                          bodyTitle
                          bodyContent
                          bodyIntroduction
                          projectVideo
                          projectImages{
                            url,
                              title,
                              width,
                              height,
                              focalPoint,
                              mimeType,
                              s400: url @transform(width: 400),
                              s800: url @transform(width: 800),
                              s1200: url @transform(width: 1200),
                              s1600: url @transform(width: 1600),
                              s2000: url @transform(width: 2000),
                              s2400: url @transform(width: 2400),
                              s2800: url @transform(width: 2800),
                              s3200: url @transform(width: 3200)
                              ...on general_Asset{
                                altText
                              }
                          }
                        }
                      }
                    }`,
        variables() {      
          return {
            slug: this.$route.params.slug
          }
        },
        result ({ data, loading, networkStatus }) {
          if (data !== undefined){
            if (data.project !== null){
              this.entry = data.project
              
              if (data.project.seoTitle !== null)
                this.$store.commit('setTitle',data.project.seoTitle)
              else
                this.$store.commit('setTitle',data.project.title)
              if (data.project.seoDescription !== null)
                this.$store.commit('setDescription',data.project.seoDescription)
              else
                this.$store.commit('setDescription','')
              this.setBylineHeight()
              // console.log('GQL Data:')
              // console.log(data.project)
              this.$store.commit('setLoaded', true)
              this.$store.commit('setCraftLoaded', true)
            } else {
              this.$router.push({ name: 'error', params: {status: 404}})
            }
          }
          else{
            this.$router.push({ name: 'error', params: {status: 500}})
          }
        },
      }
    },
    computed: {
      multiImg() {
        return this.entry.projectImages.length > 1
      },
      hasVideo() {
        return this.entry.projectVideo !== null
      },
      breakpoint() {
        return this.$store.state.breakpoint
      },
      isXsMode() {
        return this.breakpoint === 'xs' || this.breakpoint === 'sm'
      },
    },
    methods: {
      setBylineHeight() {
        if (this.$store.state.loaded && this.$refs.byline) {
          if(this.breakpoint !== 'xs' && this.breakpoint !== 'sm') {
            this.bylineHeight = this.$refs.byline.getBoundingClientRect().height + 'px'
          } else {
            this.bylineHeight = 0
          }
        } else {
          setTimeout(()=>{
            this.setBylineHeight()
          },250);
        }
      },
      imgClasses(index) {
        let fullWidth = [1,4,7,10,13,16,19,22,25,28,31]

        if(this.hasVideo) {
          fullWidth = [0,3,6,9,12,15,18,21,24,27,30]
        }

        if(fullWidth.indexOf(index) !== -1) {
          return 'md-span-2-col'
        } 
      },
      getDir(index) {
        let top    = []
        let left   = []
        let bottom = []
        let right  = []

        if(this.isXsMode) {
          left    = [1,4,7,10,13,16,19,22,25,28,31]
          right   = [2,5,8,11,14,17,20,23,26,29]
          bottom  = [0,3,6,9,12,15,18,21,24,27,30]
        } else {
          right   = [1,7,13,19,25,31]
          left    = [0,4,10,16,22,28]
          top     = [2,6,8,12,14,18,20,24,26,30]
          bottom  = [3,5,9,11,15,17,21,23,27,29]

          if(top.indexOf(index) !== -1) {
            return 'top'
          }
        }
        
        if(left.indexOf(index) !== -1) {
          return 'left'
        }
        if(right.indexOf(index) !== -1) {
          return 'right'
        }
        if(bottom.indexOf(index) !== -1) {
          return 'bottom'
        } 
      }
    },
    watch: {
      breakpoint() {
        this.setBylineHeight()
      },
    },
    mounted() {
    }
  }
</script>