<template>
  <scaffolding-helper v-if="loaded">

    <template v-slot:hero>
      <div v-if="hasVideo" class="video-wrapper">
        <video v-show="showVideo" :key="isXs" :muted="muted" loop playsinline autoplay>
          <template v-if="isXs">
            <source v-for="video in entry.videoLowRes" :src="videoURL(video)" :type="video.mimeType">
          </template>
          <template v-else>
            <source v-for="video in entry.videoHighRes" :src="videoURL(video)" :type="video.mimeType">
          </template>
        </video>
        <div class="container audio-toggle">
           <inline-svg :src="$getImageSrc(audioIcon)" @click="toggleMute()"></inline-svg>
        </div>
      </div>
      <div v-else class="img-wrapper is-cover">
        <img src="/assets/img/hero.jpg" style="object-position: 40% 60%;">
      </div>
    </template>

    <template v-slot:heading>
      <column-helper :count="2" >
        <heading-module>
          <template v-slot:heading1>{{entry.heading1}}</template>
          <template v-slot:heading2>{{entry.heading2}}</template>
          <template v-slot:heading3>{{entry.heading3}}</template>
          <template v-slot:byline>{{entry.byline}}</template>
        </heading-module>
      </column-helper>
    </template>

    <template v-slot:content>
      <column-helper :count="2">
        <div class="col keypoints-heading">
          <line-helper class="md orange"></line-helper>
          <h2 class="orange">{{entry.keypoints[0].keypointHeading}}</h2>
          <h2 v-if="entry.keypoints[0].keypointByline">{{entry.keypoints[0].keypointByline}}</h2>
        </div>
        <div class="col grid xs-gap-40 md-gap-60">
          <keypoint-module v-for="item in entry.keypoints[0].items" :data="item"></keypoint-module>
        </div>
      </column-helper>

      <column-helper :count="2" class="cta">
        <h4>Ask us how we can help with your building project.</h4>
        <h4>
          <router-link 
            :to="{name: 'contact'}" 
            :class="{'active' : enquireVisible}"
             v-observe-visibility="checkEnquire">
            Enquire now
          </router-link>
        </h4>
      </column-helper>
    </template>

  </scaffolding-helper>
</template>

<script>
  import ScaffoldingHelper from "~/components/ScaffoldingHelper.vue";
  import ColumnHelper from "~/components/ColumnHelper.vue";
  import HeadingModule from "~/components/HeadingModule.vue";
  import KeypointModule from "~/components/KeypointModule.vue";
  import LineHelper from "~/components/LineHelper.vue";
  import gql from 'graphql-tag'

  export default {
    components : { ScaffoldingHelper, ColumnHelper, HeadingModule, KeypointModule, LineHelper },
    data() {
      return {
        entry: Object,
        enquireVisible: false,
        showVideo: true,
        muted: true
      }
    },
    computed: {
      isXs() {
        return this.$store.state.breakpoint === 'xs'
      },
      hasVideo() {
        return this.entry.videoLowRes.length || this.entry.videoHighRes.length
      },
      audioIcon() {
        return (this.muted) ? 'muted-icon' : 'unmuted-icon';
      }
    },
    apollo: {
      entry: {
        query: gql`{entry(section: "home"){
                      id slug title
                      ...on home_home_Entry{
                        videoLowRes{
                          url mimeType
                        }
                        videoHighRes{
                          url mimeType
                        }
                        seoTitle seoDescription
                        heading1 heading2 heading3 byline
                        keypoints{
                          ...on keypoints_keypoints_BlockType{
                            keypointHeading keypointByline
                            items{
                              ...on items_BlockType{
                                title: itemTitle content: itemContent
                              }
                            }
                          }
                        }
                      }
                    }
                  }`,
        result ({ data, loading, networkStatus }) {
          if (data !== undefined){
            if (data.entry !== null){
              this.entry = data.entry
              
              if (data.entry.seoTitle !== null)
                this.$store.commit('setTitle',data.entry.seoTitle)
              else
                this.$store.commit('setTitle',data.entry.pageTitle)
              if (data.entry.seoDescription !== null)
                this.$store.commit('setDescription',data.entry.seoDescription)
              else
                this.$store.commit('setDescription','')
              this.$store.commit('setLoaded', true)
              this.$store.commit('setCraftLoaded', true)

              // console.log('GQL Data:')
              // console.log(data.entry)
            }
            else{
              this.$router.push({ name: 'error', params: {status: 500}})
            }
          }
          else{
            this.$router.push({ name: 'error', params: {status: 500}})
          }
        },
      }
    },
    methods: {
      checkEnquire(isVisible) {
        this.enquireVisible = isVisible
      },
      videoURL(video){
        return this.$store.state.fullURL + video.url
      },
      checkVideo(){
        this.showVideo = window.scrollY < window.innerHeight
      },
      toggleMute() {
        this.muted = !this.muted
      }
    },
    created () {
      window.addEventListener('scroll', this.checkVideo);
      window.addEventListener('resize', this.checkVideo);
    },
    destroyed () {
      window.removeEventListener('scroll', this.checkVideo);
      window.addEventListener('resize', this.checkVideo);
    },
  }
</script>