<template>
  <div id="error" class="container">
    <div v-if="is404" class="grid gap-20 inner">
      <h3 class="blue">Sorry.</h3>
      <h5>We can't seem to find the page you're looking for – here are some links that may be helpful.</h5>
      <app-nav :show-children="false" :show-home="true"></app-nav>
    </div>
    <div v-if="is500" class="grid gap-20 inner">
      <h3 class="blue">Sorry.</h3>
      <h5>Our website is unavilable due to an unscheduled outage. <span>We are investigating and expect to be back online soon.</span></h5>
      <ul>
        <li><a href="tel:006433590441">03 359 0441</a></li>
        <li><a href="mailto:enquiries@tuatarastructures.com">enquiries@tuatarastructures.com</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
  import AppNav from '~/components/AppNav'

  export default {
    components: { AppNav },
    data() {
      return {
      }
  },
  props: {
    status: {
      type: Number,
      default: 404
    }
    },
    computed: {
      is404() {
        return this.status === 404
      },
      is500() {
        return this.status === 500
      }
    }
  }
</script>