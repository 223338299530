<template>
  <column-helper :count="colCount" class="mixed-media-grid xs-gap-25 md-gap-50">

    <template v-for="(item,index) in data">

      <masked-image :direction="getDirection(index)" class="is-cover three-two">

        <!-- image -->
        <craft-image-helper v-if="item.image.length" :src="item.image[0]"></craft-image-helper>
        <!-- video -->
        <video-helper v-else :src="item.video" :ratio="'three-two'">
          <img src="/assets/img/play.svg">
        </video-helper>

      </masked-image>
    </template>

    <p v-if="description" class="description col big" :class="spanCalc">{{description}}</p>
  </column-helper>
</template>


<script>
  import ColumnHelper from "~/components/ColumnHelper.vue";
  import MaskedImage from "~/components/MaskedImage.vue";
  import VideoHelper from "~/components/VideoHelper.vue"

  export default {
    components : { ColumnHelper, MaskedImage, VideoHelper },
    props: {
      data: { // enable this
        type: Array,
        required: true
      },
      description: {
        type: String,
        required: false
      }
    },
    data() {
      return {
      }
    },
    computed: {
      colCount() {
        let count = this.data.length
        return (count === 4) ? 2 : count
      },
      spanCalc() {
        return (this.colCount === 1) ? false : `span-${this.colCount}-col`
      }
    },
    methods: {
      getDirection(index) {
        let breakpoint = this.$store.state.breakpoint
        if(breakpoint === 'xs' || breakpoint === 'sm') {
          return index % 2 ? 'left' : 'right';
        } else {
          return index % 2 ? 'top' : 'bottom';
        }
      },
    },
  }
</script>